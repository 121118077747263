// src/TermsOfService.js

import React from 'react';

function TermsOfService() {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <h1>Terms of Service</h1>
      <p><em>Last Updated: 11/2/2024</em></p>

      <h2>Welcome to TickrTrends!</h2>
      <p>
        By subscribing to our stock valuation service, you agree to the following terms and conditions. Please read them carefully before subscribing.
      </p>

      <h2>1. Subscription Terms</h2>
      <p><strong>Service Description:</strong> This subscription provides access to our curated list of stock valuations for the S&P 500, updated weekly. The list includes data-driven insights for informational purposes only.</p>
      <p><strong>Personal Use Only:</strong> By subscribing, you agree to use this data solely for your personal informational purposes. You agree not to share, distribute, or resell any of the content provided through this service.</p>
      <p><strong>Account Responsibility:</strong> You are responsible for maintaining the confidentiality of your account login information and for any activity that occurs under your account.</p>

      <h2>2. Payment and Billing</h2>
      <p><strong>Subscription Fee:</strong> The subscription fee is $4.99 per month. Your subscription will automatically renew each month unless you cancel before the renewal date.</p>
      <p><strong>Billing Cycle:</strong> Your payment method will be billed at the start of each billing cycle. You may update your payment information or cancel your subscription through your account settings.</p>

      <h2>3. Refund Policy</h2>
      <p><strong>All Sales Are Final:</strong> Due to the nature of our digital content, all sales are final. Once access to the stock valuation data has been granted, we do not offer refunds.</p>
      <p><strong>Exceptions:</strong> If you experience a technical issue that prevents you from accessing the service, please contact us at <a href="mailto:ryan@tickrtrends.com">ryan@tickrtrends.com</a> for assistance. Refunds may be granted only in cases where a technical error caused by TickrTrends prevents access to the service, at our sole discretion.</p>

      <h2>4. Disclaimer</h2>
      <p>
        The data provided by TickrTrends is for informational purposes only. It does not constitute financial advice or a recommendation to buy or sell any securities. For personalized financial advice, please consult a licensed financial advisor.
      </p>

      <h2>5. Modifications to Terms of Service</h2>
      <p>
        TickrTrends reserves the right to update these Terms of Service at any time. We will notify you of any material changes to these terms via email or by posting an updated version on our website.
      </p>

      <h2>Refund Policy</h2>
      <p>
        As detailed in our Terms of Service, all sales are final. Due to the nature of digital content, we do not provide refunds once access to our stock valuation data has been granted.
      </p>

      <p>For any support inquiries or issues accessing our service, please contact us at <a href="mailto:ryan@tickrtrends.com">ryan@tickrtrends.com</a>.</p>
    </div>
  );
}

export default TermsOfService;
