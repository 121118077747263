// Signup.js
import React, { useState } from 'react';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

function Signup() {
  // State for form fields, loading status, and error messages
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  // Signup handler
  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true); // Start loading state

    try {
      await Amplify.Auth.signUp({
        username: email,
        password,
        attributes: { email },
      });

      // On success, update the UI and inform the user
      setLoading(false);
      setSuccess(true);
      alert('Sign-up successful! Please check your email to confirm.');
    } catch (err) {
      // Display error message to the user
      setLoading(false);
      setError(err.message || 'Failed to sign up. Please try again.');
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px' }}>
      <h2>Create Your Account</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>Sign-up successful! Please check your email to confirm your account.</p>}

      <form onSubmit={handleSignup}>
        <label>Email</label>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
          style={{ margin: '10px 0', width: '100%', padding: '8px', fontSize: '16px' }}
        />

        <label>Password</label>
        <input
          type="password"
          placeholder="Enter your password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          required
          style={{ margin: '10px 0', width: '100%', padding: '8px', fontSize: '16px' }}
        />

        <button 
          type="submit"
          disabled={loading}
          style={{
            marginTop: '15px',
            width: '100%',
            padding: '10px',
            fontSize: '16px',
            backgroundColor: '#0073e6',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            opacity: loading ? '0.7' : '1',
          }}
        >
          {loading ? 'Signing Up...' : 'Sign Up'}
        </button>
      </form>
    </div>
  );
}

export default Signup;
