import React, { useState } from 'react';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { useNavigate } from 'react-router-dom';

Amplify.configure(awsExports); // Configure Amplify

const containerStyle = {
  textAlign: 'center',
  padding: '20px',
};

const formStyle = {
  display: 'inline-block',
  textAlign: 'left',
  maxWidth: '300px',
  width: '100%',
};

const inputStyle = {
  width: '100%',
  padding: '10px',
  margin: '10px 0',
  border: '1px solid #ddd',
  borderRadius: '5px',
  boxSizing: 'border-box',
};

const buttonStyle = {
  backgroundColor: '#007bff',
  color: 'white',
  padding: '10px 20px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  width: '100%',
};

const errorStyle = {
  color: 'red',
};

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const user = await Amplify.Auth.signIn(email, password);
      alert('Login successful!');
      navigate('/members'); // Redirect to MembersPage
    } catch (err) {
      setError(err.message || 'An error occurred during login.');
    }
  };

  return (
    <div style={containerStyle}>
      <h2>Login to Tickrtrends</h2>
      {error && <p style={errorStyle}>{error}</p>}
      <form onSubmit={handleLogin} style={formStyle}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={inputStyle}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={inputStyle}
        />
        <button type="submit" style={buttonStyle}>Login</button>
      </form>
    </div>
  );
}

export default Login;
