import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Link } from 'react-router-dom';
import Signup from './Signup';
import Login from './Login';
import PaymentPage from './PaymentPage';
import TermsOfService from './TermsOfService';

const buttonStyle = {
  backgroundColor: '#007bff',
  color: 'white',
  padding: '10px 20px',
  margin: '10px 5px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

const linkStyle = {
  color: '#007bff',
  textDecoration: 'none',
};

function App() {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <Routes>
        <Route 
          path="/" 
          element={
            <header style={{ maxWidth: '600px', margin: '0 auto' }}>
              <h1>Welcome to Tickrtrends</h1>
              <p>
                Access a curated, data-driven list of S&P 500 stock valuations, updated weekly, for $4.99 a month.
                This CSV list includes calculations of stocks’ forward fair value, helping you view and explore stocks 
                currently priced below their forward fair value. This data is presented for informational purposes only 
                and should not be interpreted as financial advice.
              </p>
              <p>
                <strong>Disclaimer:</strong> This data is provided for informational purposes only and does not constitute 
                financial advice or stock recommendations. Please consult a licensed financial advisor for personalized advice.
              </p>
              <button style={buttonStyle} onClick={() => navigate('/signup')}>Subscribe Now</button>
              <button style={buttonStyle} onClick={() => navigate('/login')}>Already a member? Login</button>
              <p>
                <Link to="/terms-of-service" style={linkStyle}>Terms of Service</Link>
              </p>
              <p>
                For support, contact us at <a href="mailto:ryan@tickrtrends.com" style={linkStyle}>ryan@tickrtrends.com</a>
              </p>
            </header>
          } 
        />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;