import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51Q39YhFsrgEm6mgYXxtPPY0V37yhGS8NL1Z3Ng0DnZMkJUyWgiVaZBTS7O9XJf7AxZ1AoaLFIDIi1X0loluldm6D00Nqb5UyA4');

const buttonStyle = {
  backgroundColor: '#007bff',
  color: 'white',
  padding: '10px 20px',
  margin: '20px 0',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

function PaymentPage() {
  const handlePayment = async () => {
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: 'price_1Q3AX7FsrgEm6mgYyOwygsZf', quantity: 1 }],
      mode: 'subscription',
      successUrl: 'https://2kv3mokjp5.execute-api.us-east-2.amazonaws.com/prod/payment-success',
      cancelUrl: 'https://tickrtrends.com',
    });
    if (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>Subscribe to Tickrtrends</h1>
      <button style={buttonStyle} onClick={handlePayment}>Subscribe Now</button>
    </div>
  );
}

export default PaymentPage;
